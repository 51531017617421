import styled from 'styled-components';

import { Modal, ModalBody } from 'reactstrap';
import ImageSlider from 'react-image-comparison-slider';

import Analytics from 'lib/user-analytics';
import { StyledButton } from 'components/Shared/Buttons/styled-button';
import { Products } from 'api/api-supplier';

interface ComparisonModalProps {
    isOpen: boolean;
    toggle: () => void;
    productKey?: string;
}

const ComparisonModal = ({ isOpen, toggle, productKey }: ComparisonModalProps) => {
    const product = productKey ? Products[productKey] : Products['CGA50'];
    const handleClose = () => {
        Analytics.Event('Image comparison', 'Clicked button to close');
        toggle();
    };
    return (
        <ModalContainer isOpen={isOpen} toggle={toggle}>
            <Body>
                <ImageContainer>
                    <ImageSlider
                        sliderWidth={3}
                        sliderColor="#E9D7AD"
                        handleColor="#6E322B"
                        handleBackgroundColor="#E9D7AD"
                        image2={
                            product.cmPixel === 100
                                ? `/assets/skymap50-comparison/perth-10m.png`
                                : `/assets/skymap50-comparison/10m-sample.png`
                        }
                        image1={
                            product.product === 'AN100' || product.product === 'NCN100'
                                ? `/assets/skymap50-comparison/perth-100cm-night.png`
                                : product.cmPixel === 100
                                ? `/assets/skymap50-comparison/perth-100cm.png`
                                : product.cmPixel === 75
                                ? `/assets/skymap50-comparison/75cm-sample.png`
                                : `/assets/skymap50-comparison/50cm-sample.png`
                        }
                        leftLabelText="10m/pixel"
                        rightLabelText={product.resolution}
                    />
                </ImageContainer>
                <Button className="comparison-modal-button" onClick={handleClose}>
                    CLOSE
                </Button>
            </Body>
        </ModalContainer>
    );
};

export default ComparisonModal;

const ModalContainer = styled(Modal)`
    text-align: center;
    justify-content: center;
    align-items: center;
    top: 10vh;
`;

const Body = styled(ModalBody)`
    position: absolute;
    border-radius: 4px;
    margin-left: -150px;
    width: 630px;
    height: 550px;
    background-color: ${(props) => props.theme.primary.color};
`;

const ImageContainer = styled.div`
    width: 580px;
    height: 450px;
    max-width: 800px;
    max-height: 450px;
    z-index: 999;
    margin: auto;

    img {
        height: 100% !important;
        width: unset;
    }
`;

const Button = styled(StyledButton)`
    margin: 15px auto;
`;
