import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import styled from 'styled-components';
import Analytics from '../../../lib/user-analytics';
import UserHelper from '../../../lib/user-helper';
import { actionGetMyProfile } from '../../../store/App/actions';
import { selectMyProfile } from '../../../store/App/selectors';
import ProfileDeleteModal from '../Profile/profile-delete-modal';
import NavigationPanel from '../navigation-panel';
import { StyledButton } from '../../Shared/Buttons/styled-button';
import SuccessResetPasswordModal from '../Profile/success-reset-password-modal';

import CognitoUtil from 'lib/cognito-util';

const AccountSettings = () => {
    const [oldPassword, setOldPassword] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

    const handleSubmit = () => {
        setIsSubmitting(true);

        if (!UserHelper.isPasswordValid(password)) {
            setIsSubmitting(false);
            setError('Password must be at least 6 characters');
            setPassword('');
            setConfirmPassword('');
            return;
        }

        if (oldPassword === confirmPassword) {
            setIsSubmitting(false);
            setError('Your new password is not valid');
            setPassword('');
            setOldPassword('');
            setConfirmPassword('');
            return;
        }

        if (password !== confirmPassword) {
            setIsSubmitting(false);
            setError('Confirm password does not match');
            setPassword('');
            setConfirmPassword('');
            return;
        }

        if (oldPassword !== oldPassword) {
            setIsSubmitting(false);
            setError('Incorrect old password');
            setPassword('');
            setConfirmPassword('');
            return;
        }

        const accessToken = UserHelper.getAccessToken();
        if (accessToken) {
            CognitoUtil.updatePassword(oldPassword, confirmPassword, accessToken)
                .then(() => {
                    setIsSubmitting(false);
                    setHasSubmitted(true);
                    setPassword('');
                    setOldPassword('');
                    setConfirmPassword('');
                    Analytics.Event('My Dashboard', 'Clicked to reset password');
                })
                .catch((err) => {
                    setError(err.toString().replace('Error: 400: ', ''));
                    setOldPassword('');
                    setPassword('');
                    setConfirmPassword('');
                    setIsSubmitting(false);
                });
        }
    };

    const [error, setError] = useState<string | undefined>(undefined);
    const [openDeleteAccount, setOpenDeleteAccount] = useState<boolean>(false);
    const toggleDeleteAccount = () => setOpenDeleteAccount(!openDeleteAccount);
    const myProfile = useSelector(selectMyProfile);
    const dispatch = useDispatch();
    const getMyProfile = useCallback(() => dispatch(actionGetMyProfile()), [dispatch]);

    useEffect(() => {
        if (!myProfile) {
            getMyProfile();
        }
    }, [getMyProfile, myProfile]);

    return (
        <React.Fragment>
            <NavigationPanel>
                <h3>Account Settings</h3>
            </NavigationPanel>
            <ColumnCard>
                <ResetPasswordContainer>
                    <h3>Reset Password</h3>
                    <ResetPasswordBody>
                        <p>
                            <i>Resetting your password will also reset your Soar.Earth password</i>
                        </p>
                        <FormGroup row>
                            <Label for="oldPassword" sm={3}>
                                Original Password
                            </Label>
                            <Col sm={9}>
                                <PasswordInput
                                    data-sentry-block
                                    autoComplete="current-password"
                                    type="password"
                                    name={oldPassword}
                                    placeholder="Enter your original password..."
                                    value={oldPassword}
                                    data-testid="original-password"
                                    onChange={(e) => {
                                        setOldPassword(e.target.value);
                                        setError(undefined);
                                    }}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="password" sm={3}>
                                New Password
                            </Label>
                            <Col sm={9}>
                                <PasswordInput
                                    data-sentry-block
                                    autoComplete="new-password"
                                    type="password"
                                    name={password}
                                    placeholder="Enter your new password..."
                                    data-testid="new-password"
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        setError(undefined);
                                    }}
                                />

                                <PasswordNote>
                                    <p>Password must be at least 6 characters</p>
                                </PasswordNote>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="confirmPassword" sm={3}>
                                Confirm Password
                            </Label>
                            <Col sm={9}>
                                <PasswordInput
                                    data-sentry-block
                                    autoComplete="new-password"
                                    type="password"
                                    name={confirmPassword}
                                    placeholder="Confirm your new password"
                                    data-testid="confirm-password"
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        setConfirmPassword(e.target.value);
                                        setError(undefined);
                                    }}
                                />
                            </Col>
                        </FormGroup>

                        {error && (
                            <Row>
                                <Col sm={3} />
                                <Col sm={9}>
                                    <PasswordModalError>
                                        <PasswordModalErrorIcon />
                                        {error}
                                    </PasswordModalError>
                                </Col>
                            </Row>
                        )}

                        <Row>
                            <Col sm={3} />
                            <Col sm={9} className="d-flex justify-content-end">
                                {isSubmitting && !hasSubmitted && (
                                    <ResetPasswordSubmittingContainer>
                                        <Spinner style={{ height: '40px', width: '40px' }} />
                                    </ResetPasswordSubmittingContainer>
                                )}
                                {!isSubmitting && !hasSubmitted && (
                                    <ResetSubmitButton onClick={() => handleSubmit()}>
                                        CHANGE PASSWORD
                                    </ResetSubmitButton>
                                )}
                            </Col>
                        </Row>
                    </ResetPasswordBody>
                    {hasSubmitted && !isSubmitting && (
                        <SuccessResetPasswordModal
                            openSuccessModal={hasSubmitted}
                            toggle={() => setHasSubmitted(false)}
                            email={myProfile?.email}
                            onClose={() => {
                                setHasSubmitted(false);
                            }}
                        />
                    )}
                </ResetPasswordContainer>

                <DeleteAccountContainer>
                    <h3>Delete Account</h3>
                    <DeleteAccountBody>
                        <p>
                            Deleting your account will delete your access and all your information on this site and on
                            Soar.Earth
                        </p>
                        <DeleteAccountButton onClick={toggleDeleteAccount}>DELETE</DeleteAccountButton>
                        {openDeleteAccount && (
                            <ProfileDeleteModal userId={myProfile?.userId} toggle={toggleDeleteAccount} />
                        )}
                    </DeleteAccountBody>
                </DeleteAccountContainer>
            </ColumnCard>
        </React.Fragment>
    );
};

export default AccountSettings;

const ColumnCard = styled(Col)`
    background-color: ${(props) => props.theme.primary.color};
    padding: 20px;
    border-radius: 6px;
    color: ${(props) => props.theme.primary.text};
    margin-top: 15px;
    margin-bottom: 15px;
`;

const ResetPasswordContainer = styled.div``;

const ResetPasswordBody = styled.div`
    display: block;
    align-self: center;
    text-align: left;
    justify-content: center;
    overflow: hidden;
    margin-top: 20px;
    margin-left: 5rem;
    max-width: 750px;

    @media only screen and (max-width: 600px) {
        text-align: center;
        margin: 0px;
    }
`;

const PasswordInput = styled(Input)`
    background-color: ${(props) => props.theme.primary.color};
    border: 1px solid ${(props) => props.theme.secondary.color}99;
    color: ${(props) => props.theme.primary.text} !important;

    @media only screen and (max-width: 600px) {
        max-width: 300px;
        margin: 0px 50px 0px 40px;
        display: block;
    }

    :hover,
    :focus {
        background-color: ${(props) => props.theme.primary.color};
        border: 1.5px solid ${(props) => props.theme.secondary.color}99;
        box-shadow: 0 0 0 0.2rem ${(props) => props.theme.secondary.color}1f;
    }

    @media only screen and (max-width: 600px) {
        max-width: 300px;
        margin: 0px 50px 0px 40px;
        display: block;
    }
`;

const PasswordNote = styled.div`
    p {
        font-style: italic;
        font-size: 14px;
        margin-bottom: 5px;
    }
`;

const ResetSubmitButton = styled(StyledButton)`
    margin: 0px 0px 0px 18px;
    display: inline-block;
    padding: 10px;

    &:hover {
        opacity: 0.6;
    }

    @media screen and (max-width: 600px) {
        margin: 0 auto;
    }
`;

const ResetPasswordSubmittingContainer = styled.div`
    width: 100%;
    text-align: center;
`;

const PasswordModalError = styled.div`
    color: ${(props) => props.theme.secondary.color};
    -webkit-text-fill-color: ${(props) => props.theme.secondary.color};
    padding: 0px;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media only screen and (max-width: 600px) {
        margin: 0px;
    }
`;

const PasswordModalErrorIcon = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 17V29' stroke='%236E322B' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20 11V13' stroke='%236E322B' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='20' cy='20' r='19.5' stroke='%236E322B'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    float: left;

    @media only screen and (max-width: 600px) {
        margin: 0px 10px 0px 10px;
    }
`;

const DeleteAccountContainer = styled.div`
    margin-top: 50px;
    align-items: flex-end;

    h3 {
        color: crimson;
        -webkit-text-fill-color: crimson;
        text-align: left;
        padding: 24px 0px 0px 18px;
    }

    @media screen and (max-width: 600px) {
        align-items: center;
    }
`;

const DeleteAccountBody = styled.div`
    display: block;
    align-self: center;
    text-align: left;
    justify-content: center;
    overflow: hidden;
    margin-top: 20px;
    margin-left: 5rem;
    max-width: 750px;

    @media only screen and (max-width: 600px) {
        text-align: center;
        margin: 0px;
    }
`;

const DeleteAccountButton = styled.button`
    background-color: crimson;
    border: 1px solid crimson;
    border-radius: 4px;
    padding: 8px 25px;
    float: right;
    color: ${(props) => props.theme.primary.color};
    font-weight: 500;
    display: flex;

    &:hover {
        opacity: 0.6;
    }

    @media only screen and (max-width: 600px) {
        overflow-x: none;
        float: none;
        margin: 0 auto;
        text-align: center;
        display: block;
    }
`;
