import Analytics from 'lib/user-analytics';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

//const GAMEBOY_SCALE = 0.5;

const LandingPage = () => {
    const navigate = useNavigate();
    const handleClickGameboy = () => {
        Analytics.Event('Landing page', 'Clicked Gameboy to launch Pics from Space');
        navigate('/satellite');
    };

    return (
        <BackgroundContainer>
            <Contents>
                <LandingPageLogo src="/assets/logos/logo-Pics-from-Space-white_original.png" />
                <h2>Ever wanted a picture from space?</h2>
                <Gameboy
                    src="/assets/landing-page/Device-with-GIF_landing-page.gif"
                    onClick={handleClickGameboy}
                    title="Click to launch Pics from Space"
                />
                <LowerHalfContainer>
                    <IntroContainer>
                        <IntroText>
                            So you want a pic from space? Easy!
                            <br />
                            <br />
                            Pics from Space offers high resolution images from orbiting satellites in an easy to use
                            interface and at a great price.
                            <br />
                            <br />
                            Whether you are after archival images, or you want to program a satellite to take a new
                            image that you can't find on Google Earth, Pics from Space can deliver.
                            <br />
                            <br />
                            Images from Pics from Space can assist projects such as:
                            <div className="d-flex pt-2">
                                <ul className="ml-2">
                                    <li>mining</li>
                                    <li>agriculture</li>
                                    <li>real estate</li>
                                    <li>environmental research</li>
                                    <li>carbon mapping</li>
                                </ul>
                                <ul className="ml-5">
                                    <li>ESG</li>
                                    <li>archaeology</li>
                                    <li>news</li>
                                    <li>media</li>
                                    <li>and evening gaming!</li>
                                </ul>
                            </div>
                            Pics from Space - buying satellite imagery has never been easier.
                        </IntroText>
                    </IntroContainer>
                    <FooterContainer>
                        <Watermark src="/assets/logos/soar-logo-WATERMARK.png" />
                        <div className="mt-2 text-center">
                            <div>© 2023 Soar.Earth Ltd</div>
                            <div className="d-flex justify-content-center">
                                <Link to="/terms">Terms of Service</Link>
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                <Link to="/privacy">Privacy Policy</Link>
                            </div>
                        </div>
                    </FooterContainer>
                </LowerHalfContainer>
            </Contents>
        </BackgroundContainer>
    );
};

export default LandingPage;

const BackgroundContainer = styled.div`
    height: 100vh;
    width: 100vw;

    position: fixed;
    background: url('/assets/landing-page/landing_bg_v3.jpg');
    background-size: cover;
    background-position: center center;
`;

const Contents = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    width: 100vw;
    overflow: auto;

    h2 {
        text-align: center;
        color: ${(props) => props.theme.primary.color};
        letter-spacing: 5px;
        margin-top: -10px;
        padding-bottom: 12px;
        font-size: 29px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 8px;
        -webkit-box-shadow: inset 0 0 8px;
        -webkit-box-shadow-color: ${(props) => props.theme.primary.color};
        background-color: ${(props) => props.theme.primary.color};
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: ${(props) => props.theme.primary.color};
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 8px;
        -webkit-box-shadow: inset 0 0 8px;
        -webkit-box-shadow-color: ${(props) => props.theme.primary.color};
        background-color: ${(props) => props.theme.secondary.color};
        border-radius: 4px;
    }
`;

const IntroContainer = styled.div`
    margin-top: 25px;
    max-width: 800px;
    background-color: ${(props) => props.theme.primary.color};
    border-radius: 6px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
`;

const IntroText = styled.div`
    max-width: 800px;
    color: ${(props) => props.theme.secondary.color};
    font-size: 16px;
    line-height: 1.2;
    margin: 15px;
`;

const Gameboy = styled.img`
    height: calc(350px + 12vh);
    cursor: pointer;
    margin-top: 12px;
`;

const Watermark = styled.img`
    width: 130px;
    height: 25px;
`;

const LandingPageLogo = styled.img`
    width: 700px;
    max-width: 900px;
    align-items: center;
    justify-content: center;
    display: block;
    margin: 0 auto;
    padding: 12px;
`;

const LowerHalfContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    height: 100%;
    justify-content: space-between;
`;

const FooterContainer = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(0 0 0 / 35%);
    padding: 10px 15px;
    border-radius: 7px;

    div {
        color: rgb(255 255 255 / 45%) !important;
    }
    a {
        text-decoration-line: underline;
        color: rgb(255 255 255 / 45%) !important;
    }
`;
