import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, FormGroup, Input, Label, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import ApiUser from '../../api/api-user';
import Analytics from '../../lib/user-analytics';
import UserHelper from '../../lib/user-helper';
import { actionLoginSuccess } from '../../store/App/actions';
import { StyledButton } from '../Shared/Buttons/styled-button';
import Spinner from '../Shared/Spinner/spinner';

import CognitoUtil, { CognitoAuthResultType } from 'lib/cognito-util';

interface RegisterProps {
    email: string;
    onEmailChange: (email: string) => void;
    onClose: () => void;
    handleClickLogin: () => void;
    onRegisterSuccess: () => void;
}

const Register = (props: RegisterProps) => {
    const dispatch = useDispatch();
    const loginSuccess = (authResult: CognitoAuthResultType) => dispatch(actionLoginSuccess(authResult));

    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
    const [newsletterSignUp, setNewsletterSignUp] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const submitRegister = async () => {
        setIsSubmitting(true);

        try {
            //@ts-ignore
            window.gtag('config', 'AW-752086192');

            //@ts-ignore
            window.gtag('event', 'conversion', { send_to: 'AW-752086192/e-4PCLXvj_kBELDZz-YC' });
        } catch {
            //do nothing for now, implemented this try catch so the integration testing will work
        }

        try {
            // TODO: Adds newsletter sign-up field to endpoint.
            await ApiUser.signup(
                props.email,
                password,
                firstName + ' ' + lastName,
                firstName,
                lastName,
                newsletterSignUp
            );

            await CognitoUtil.login(props.email, password)
                .then((loginAuth) => {
                    loginSuccess(loginAuth);
                    Analytics.Event('Registration', 'Registered', `${firstName} ${lastName}`);
                    props.onRegisterSuccess();
                })
                .catch((err) => {
                    setError(err.message);
                });
        } catch (err) {
            const message: string = err.message;
            if (message) {
                setError(message);
            } else {
                setError('Something went wrong');
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleSubmitRegister = (e) => {
        e.preventDefault();
        setError(undefined);
        if (!firstName || !lastName) {
            setError('Please check your name');
            return;
        }

        if (!UserHelper.isEmailValid(props.email)) {
            setError('Please check your email');
            return;
        }

        if (password.length < 6) {
            setError('Please enter a password that is at least 6 characters long');
            return;
        }

        if (!acceptedTerms) {
            setError('You must accept the terms and conditions to register');
            return;
        }

        if (firstName && lastName && props.email && password && acceptedTerms) {
            submitRegister();
        }
        Analytics.Event('Registration', 'Clicked Create Account');
    };

    const handleExistingAccount = () => {
        Analytics.Event('Registration', 'Clicked Sign in here link');
        props.handleClickLogin();
    };

    return (
        <React.Fragment>
            <RegisterModalBody>
                <Info>
                    <i>Note:</i>&nbsp; Creating an account in Pics from Space will also create an account in Soar.Earth
                </Info>
                <Form onSubmit={(e) => handleSubmitRegister(e)}>
                    <FormGroup>
                        <Label for="firstName">Enter your name</Label>

                        <RegisterFormName>
                            <Input
                                data-testid="first-name"
                                style={{ marginRight: '5px' }}
                                placeholder="First Name..."
                                value={firstName}
                                onChange={(e) => {
                                    setFirstName(e.target.value);
                                    setError(undefined);
                                }}
                                onClick={() => {
                                    Analytics.Event('Registration', 'Clicked First Name input');
                                }}
                            />
                            <Input
                                data-testid="last-name"
                                placeholder="Last Name..."
                                value={lastName}
                                onChange={(e) => {
                                    setLastName(e.target.value);
                                    setError(undefined);
                                }}
                                onClick={() => {
                                    Analytics.Event('Registration', 'Clicked Last Name input');
                                }}
                            />
                        </RegisterFormName>
                    </FormGroup>
                    <FormGroup>
                        <Label for="email">Email</Label>

                        <Input
                            data-testid="email"
                            type="email"
                            placeholder="Email Address"
                            value={props.email}
                            onChange={(e) => {
                                props.onEmailChange(e.target.value);
                                setError(undefined);
                            }}
                            onClick={() => {
                                Analytics.Event('Registration', 'Clicked Email input');
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="password">Create Password</Label>

                        <Input
                            data-sentry-block
                            data-testid="password"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            placeholder="Password"
                            onChange={(e) => {
                                setPassword(e.target.value);
                                setError(undefined);
                            }}
                            onClick={() => {
                                Analytics.Event('Registration', 'Clicked Password input');
                            }}
                        />
                        <RegisterFormPasswordIcon
                            showPassword={showPassword}
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <RegisterAcceptTerms>
                            <input
                                style={{ marginRight: '10px' }}
                                type="checkbox"
                                checked={acceptedTerms}
                                onChange={() => setAcceptedTerms(!acceptedTerms)}
                                onClick={() => {
                                    Analytics.Event(
                                        'Registration',
                                        'Clicked to accept terms and privacy',
                                        'Terms and Privacy checkbox'
                                    );
                                }}
                            />
                            I have read, understood and agree to the
                            <RegisterLink
                                target="_blank"
                                href="/terms"
                                onClick={() => {
                                    Analytics.Event('Registration', 'Clicked Terms of Service link');
                                }}
                            >
                                Terms of Service
                            </RegisterLink>{' '}
                            and
                            <RegisterLink
                                target="_blank"
                                href="/privacy"
                                onClick={() => {
                                    Analytics.Event('Registration', 'Clicked Privacy Policy link');
                                }}
                            >
                                Privacy Policy
                            </RegisterLink>
                        </RegisterAcceptTerms>

                        <RegisterAcceptTerms>
                            <input
                                style={{ marginRight: '10px' }}
                                type="checkbox"
                                checked={newsletterSignUp}
                                onChange={() => setNewsletterSignUp(!newsletterSignUp)}
                                onClick={() => {
                                    Analytics.Event('Registration', 'Clicked Sign up to newsletter checkbox');
                                }}
                            />
                            I would like to sign up to the newsletter to receive regular updates.
                        </RegisterAcceptTerms>
                    </FormGroup>
                    {error && (
                        <RegisterModalError>
                            <RegisterModalErrorIcon />
                            {error}
                        </RegisterModalError>
                    )}
                    {!isSubmitting ? (
                        <RegisterSubmitButton onClick={(e) => handleSubmitRegister(e)} error={error ? true : false}>
                            CREATE ACCOUNT
                        </RegisterSubmitButton>
                    ) : (
                        <Spinner />
                    )}
                </Form>
            </RegisterModalBody>
            <RegisterModalFooter>
                Already have an account?
                <RegisterLink onClick={handleExistingAccount}>Sign in here</RegisterLink>
            </RegisterModalFooter>
        </React.Fragment>
    );
};

export default Register;

const RegisterModalError = styled.div`
    color: ${(props) => props.theme.secondary.color};
    -webkit-text-fill-color: ${(props) => props.theme.secondary.color};
    padding: 10px;
    padding-top: 0px;
    display: flex;
`;

const RegisterModalErrorIcon = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 17V29' stroke='%236E322B' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20 11V13' stroke='%236E322B' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='20' cy='20' r='19.5' stroke='%236E322B'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;

const RegisterModalBody = styled(ModalBody)`
    color: ${(props) => props.theme.secondary.color};

    label {
        color: ${(props) => props.theme.secondary.color};
        width: 100%;
    }

    input {
        background-color: transparent;
        color: ${(props) => props.theme.primary.hover};
        border: 1px solid;
        border-color: ${(props) => props.theme.secondary.color};
        -webkit-text-fill-color: ${(props) => props.theme.primary.hover};

        :hover {
            border: 1px solid;
            border-color: ${(props) => props.theme.secondary.color};
            -webkit-text-fill-color: ${(props) => props.theme.primary.hover};
            box-shadow: ${(props) => props.theme.boxShadow.shallow};
        }

        :focus {
            background-color: transparent;
            border: 1px solid;
            border-color: ${(props) => props.theme.secondary.color};
            -webkit-text-fill-color: ${(props) => props.theme.primary.hover};
            box-shadow: ${(props) => props.theme.boxShadow.shallow};
        }
    }
`;

const RegisterFormName = styled.div`
    display: inline-flex;
`;

interface PasswordIconProps {
    showPassword: boolean;
}

const RegisterFormPasswordIcon = styled.div<PasswordIconProps>`
    background-image: url('${(props) => (props.showPassword ? passwordActiveUrl : passwordInActiveUrl)}');
    background-repeat: no-repeat;
    width: 25px;
    height: 20px;
    float: right;
    margin-top: -26px;
    margin-right: 5px;
`;

const passwordActiveUrl =
    "data:image/svg+xml,%3Csvg width='24' height='20' viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.5'%3E%3Cpath d='M0.999971 10.0023L0.554051 9.77608C0.481944 9.91824 0.481944 10.0863 0.554051 10.2284L0.999971 10.0023ZM23 10.0023L23.4459 10.2284C23.518 10.0863 23.518 9.91824 23.4459 9.77608L23 10.0023ZM1.44589 10.2284C3.85237 5.4839 7.72171 2.5 12 2.5V1.5C7.21666 1.5 3.06385 4.82785 0.554051 9.77608L1.44589 10.2284ZM12 2.5C16.2782 2.5 20.1476 5.4839 22.5541 10.2284L23.4459 9.77608C20.9361 4.82785 16.7833 1.5 12 1.5V2.5ZM22.5541 9.77608C20.1476 14.5206 16.2782 17.5045 12 17.5045V18.5045C16.7833 18.5045 20.9361 15.1767 23.4459 10.2284L22.5541 9.77608ZM12 17.5045C7.72171 17.5045 3.85237 14.5206 1.44589 9.77608L0.554051 10.2284C3.06385 15.1767 7.21666 18.5045";
const passwordInActiveUrl =
    "data:image/svg+xml,%3Csvg width='24' height='20' viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.5'%3E%3Cpath d='M21 1L3 19' stroke='%236E322B' stroke-linecap='round'/%3E%3Cpath d='M1 10.0023L0.55408 9.77608C0.481973 9.91824 0.481973 10.0863 0.55408 10.2284L1 10.0023ZM23 10.0023L23.4459 10.2284C23.518 10.0863 23.518 9.91824 23.4459 9.77608L23 10.0023ZM1.44592 10.2284C3.8524 5.4839 7.72174 2.5 12 2.5V1.5C7.21669 1.5 3.06388 4.82785 0.55408 9.77608L1.44592 10.2284ZM12 2.5C16.2783 2.5 20.1476 5.4839 22.5541 10.2284L23.4459 9.77608C20.9361 4.82785 16.7833 1.5 12 1.5V2.5ZM22.5541 9.77608C20.1476 14.5206 16.2783 17.5045 12 17.5045V18.5045C16.7833 18.5045 20.9361 15.1767 23.4459 10.2284L22.5541 9.77608ZM12 17.5045C7.72174 17.5045 3.8524 14.5206 1.44592 9.77608L0.55408 10.2284C3.06388 15.1767 7.21669 18.5045 12 18.5045V17.5045Z' fill='white'/%3E%3Ccircle cx='12' cy='10' r='4' stroke='%236E322B' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E";

const RegisterAcceptTerms = styled.div`
    color: ${(props) => props.theme.primary.hover};
    font-size: 12px;
    text-align: left;
    margin: 0 0 12px 12px;
`;

interface ErrorProps {
    error: boolean;
}

const RegisterSubmitButton = styled(StyledButton)<ErrorProps>`
    margin: 0 auto;
    margin-top: ${(props) => (props.error ? '0px' : '20px')};
    margin-bottom: 10px;
    background-color: ${(props) => props.theme.secondary.color};
    width: 100%;

    :not(:disabled) {
        cursor: pointer;
        background-color: ${(props) => props.theme.secondary.color};
        color: ${(props) => props.theme.primary.color};
    }
`;

const RegisterModalFooter = styled(ModalFooter)`
    color: ${(props) => props.theme.primary.hover};
    border-top: 1px solid;
    border-color: ${(props) => props.theme.secondary.color};
    display: block;
    text-align: center;

    a {
        color: ${(props) => props.theme.secondary.color};
    }
`;

const RegisterLink = styled.a`
    color: ${(props) => props.theme.secondary.color};
    -webkit-text-fill-color: ${(props) => props.theme.secondary.color};
    margin-left: 3px;
    text-decoration: underline !important;
    cursor: pointer;
`;

const Info = styled.div`
    color: ${(props) => props.theme.secondary.color};
    margin: 10px;
    display: flex;
`;
