import { LatLngBounds } from 'leaflet';
import GeoUtil from '../lib/geo-util';
import Api from './api';
import { CountryInfo, Industry, OrderDetailsDTO } from './model';
import store from 'store/store';
import TurfGeoUtil from 'lib/turf-geo-util';

// TODO: Receive these from the backend
export type SupplierCode = 'CSW' | 'CGSTL' | '21AT' | 'SIIS' | 'SKYM50' | 'UNKNOWN';

// TODO: Receive these from the backend
// NT50 = New Task 50cm
// AC50 = Archive 50cm
// A75 = Archive 75cm
// A100 = Archive 100cm
export type ProductCode =
    | 'A50'
    | 'A75'
    | 'A100'
    | 'AN100'
    | 'NC50'
    | 'NC30'
    | 'NC75'
    | 'NC75-ST'
    | 'NCN100'
    | 'NC100'
    | 'NC500';

export type OrderType = 'ARCHIVE' | 'NEWCOLLECT';

type ISupplier = {
    [key: string]: { name: string };
};

type IProduct = {
    [key: string]: {
        product: ProductCode;
        supplier: SupplierCode;
        type: OrderType;
        resolution: string;
        minAreaInKm: number;
        cmPixel: number;
    };
};

export const Suppliers: ISupplier = {
    CGSTL: { name: 'CG Satellite' },
    SKYM50: { name: 'Skymap 50' },
};

export const Products: IProduct = {
    //CGNC30: { product: 'NC30', supplier: 'CGSTL', type: 'NEWCOLLECT', resolution: '30 cm/pixel', minAreaInKm: 100 },
    CGNC50: {
        product: 'NC50',
        supplier: 'CGSTL',
        type: 'NEWCOLLECT',
        resolution: '50 cm/pixel',
        minAreaInKm: 50,
        cmPixel: 50,
    },
    CGNC75: {
        product: 'NC75',
        supplier: 'CGSTL',
        type: 'NEWCOLLECT',
        resolution: '75 cm/pixel',
        minAreaInKm: 50,
        cmPixel: 75,
    },
    //  CGNC75ST: { product: 'NC75-ST', supplier: 'CGSTL', type: 'NEWCOLLECT', resolution: 'Stereo 75 cm/pixel' },
    CGNC100: {
        product: 'NC100',
        supplier: 'CGSTL',
        type: 'NEWCOLLECT',
        resolution: '100 cm/pixel',
        minAreaInKm: 50,
        cmPixel: 100,
    },
    // CGNCN100: {
    //     product: 'NCN100',
    //     supplier: 'CGSTL',
    //     type: 'NEWCOLLECT',
    //     resolution: 'Night 100cm/pixel',
    //     minAreaInKm: 50,
    // },
    // CGNC500: { product: 'NC500', supplier: 'CGSTL', type: 'NEWCOLLECT', resolution: '500 cm/pixel' },
    CGA50: {
        product: 'A50',
        supplier: 'CGSTL',
        type: 'ARCHIVE',
        resolution: '50 cm/pixel',
        minAreaInKm: 10,
        cmPixel: 50,
    },
    CGA75: {
        product: 'A75',
        supplier: 'CGSTL',
        type: 'ARCHIVE',
        resolution: '75 cm/pixel',
        minAreaInKm: 10,
        cmPixel: 75,
    },
    CGA100: {
        product: 'A100',
        supplier: 'CGSTL',
        type: 'ARCHIVE',
        resolution: '100 cm/pixel',
        minAreaInKm: 10,
        cmPixel: 100,
    },
    CGAN100: {
        product: 'AN100',
        supplier: 'CGSTL',
        type: 'ARCHIVE',
        resolution: 'Night 100cm/pixel',
        minAreaInKm: 10,
        cmPixel: 100,
    },
};

export const DefaultNewCollectProductKey = 'CGNC50';

// TODO: Abstract data type with concrete suppliers
export interface SatelliteArchiveImageryDTO {
    cloud: number;
    date: Date;
    description: string;
    geometryWKT: string;
    id: string;
    previewUrl: string;
    product: string;
    resolutionInCm: string;
    rollAngle: string;
    supplier: string;
}

export interface SatelliteArchiveCostDTO {
    archiveSelectedAreaInKm: number;
    totalPrice: number;
    minAreaInKm: number;
    totalTax: number;
    voucherValueUsed: number;
    maxAreaInKm: number;
    countryCode: string;
    minLengthOrWidthInKm: number;
    pricePerKm: number;
    currency: string;
    currencyRate: number;
    effectiveAreaInKm: number;
    areaInKm: number;
}

export type SatelliteArchiveOrderDTO = SatelliteArchiveCostDTO &
    SatelliteArchiveImageryDTO & { orderType: OrderType; productKey: string };

export default class ApiSupplier extends Api {
    public static getOrderDetails(id: number): Promise<OrderDetailsDTO> {
        return this.axios
            .get('/v1/orders/' + id, { cache: true })
            .then((res) => res.data)
            .then((data) => {
                data.boundingBox = GeoUtil.latLngBoundsFromPolygonWKT(data.geometryWKT);
                return data;
            });
    }

    public static searchArchive(
        aoi: LatLngBounds,
        supplierCode: SupplierCode,
        productCode: ProductCode
    ): Promise<SatelliteArchiveImageryDTO[]> {
        const wkt = GeoUtil.latLngBoundsToWKT(aoi);
        const params = {
            supplier: supplierCode,
            product: productCode,
            geometry: wkt,
            additionalTax: 0,
            discount: 0,
        };

        return this.axios
            .get('/v1/archive/search', { params: params })
            .then((res) => res.data)
            .then((data) => {
                return data.list.map((t) => t as SatelliteArchiveImageryDTO);
            })
            .then((results) => {
                // Sort by newest first
                return results.sort((a, b) => {
                    const dateA = new Date(a.date).getTime();
                    const dateB = new Date(b.date).getTime();
                    return dateB - dateA;
                });
            });
    }

    public static searchArchives(
        aoi: LatLngBounds,
        supplierCode: SupplierCode,
        productCodes: ProductCode[]
    ): Promise<SatelliteArchiveImageryDTO[]> {
        const wkt = GeoUtil.latLngBoundsToWKT(aoi);

        return Promise.all(
            productCodes.map((productCode) => {
                const params = {
                    supplier: supplierCode,
                    product: productCode,
                    geometry: wkt,
                    additionalTax: 0,
                    discount: 0,
                };
                return this.axios.get('/v1/archive/search', { params: params }).then((res) => res.data.list);
            })
        )
            .then((list: SatelliteArchiveImageryDTO[][]) => {
                const merged = list.reduce((arr, item) => {
                    arr.push(...item);
                    return arr;
                }, []);
                return merged;
            })
            .then((results) => {
                // Sort by newest first
                return results.sort((a, b) => {
                    const dateA = new Date(a.date).getTime();
                    const dateB = new Date(b.date).getTime();
                    return dateB - dateA;
                });
            })
            .then((results) => {
                //include results within aoi atleast 15%
                return results.filter((scene) => {
                    if (!aoi.intersects(GeoUtil.latLngBoundsFromPolygonWKT(scene.geometryWKT))) return false;

                    const percent = TurfGeoUtil.intersectPercent(wkt, scene.geometryWKT);
                    return percent >= 15;
                });
            })
            .catch((error: Error) => {
                const areaInKm = TurfGeoUtil.areaWkt(wkt).toFixed(2);
                if (error.cause === 'geometry-invalid') {
                    error.message = `Area can not be smaller than 10.0 km²`;
                } else if (error.cause === 'aoi-too-big') {
                    error.message = `Area [${areaInKm} km²] can not be bigger than 10000.0 km²`;
                }
                throw error;
            });
    }

    public static recalCalculatePrices(countryCode?: string): Promise<SatelliteArchiveOrderDTO[]> {
        const items = store.getState().shoppingCartDomain.satelliteArchiveOrders;

        return Promise.all(
            items.map((item) => {
                if (item.orderType === 'ARCHIVE') {
                    return this.calculateArchivePrice(item.geometryWKT, item, countryCode);
                } else {
                    return this.calculateNewCollectPrice(item.geometryWKT, item.productKey, countryCode);
                }
            })
        );
    }

    public static calculateArchivePrice(
        geometryWKT: string,
        archive: SatelliteArchiveImageryDTO,
        countryCode?: string,
        voucher?: string
    ): Promise<SatelliteArchiveOrderDTO> {
        const productKey = Object.keys(Products).find(
            (p) => Products[p].supplier === archive.supplier && Products[p].product === archive.product
        );

        const params = {
            supplier: archive.supplier,
            product: archive.product,
            geometry: geometryWKT,
            archives: archive.id,
            additionalTax: 0, // TODO: Ask about this additional tax field
            discount: 0,
            voucher: voucher || '',
            countryCode: countryCode || '',
        };
        return this.axios
            .get('/v1/archive/calculate_price', { params: params, cache: false })
            .then((res) => {
                return { ...archive, ...res.data, geometryWKT, orderType: 'ARCHIVE', productKey };
            })
            .catch((error: Error) => {
                if (error.cause === 'selected-archive-too-small') {
                    const intersectArea = TurfGeoUtil.intersectArea(geometryWKT, archive.geometryWKT);
                    error.message = `The scene from space the minimum order must be larger than 10km². 
                    Currently your order is ${intersectArea.toFixed(2)}km²`;
                }
                throw error;
            });
    }

    //calculating new collect price
    public static calculateNewCollectPrice(
        geometryWKT: string,
        productKey: string,
        countryCode?: string
    ): Promise<SatelliteArchiveOrderDTO> {
        const product = Products[productKey];
        const params = {
            supplier: product.supplier,
            product: product.product,
            geometry: geometryWKT,
            countryCode: countryCode ?? '',
            voucher: '',
        };

        //override the areaInKm from api as there is a minor difference with the computation, and just use the frontend
        //computation for consistency in UI
        const areaInKm = TurfGeoUtil.areaWkt(geometryWKT).toFixed(2);
        return this.axios
            .get('/v1/tasking/calculate_price', { params: params, cache: false })
            .then((res) => {
                return {
                    ...res.data,
                    ...params,
                    geometryWKT,
                    orderType: 'NEWCOLLECT',
                    id: new Date().getTime().toString(),
                    productKey,
                    areaInKm,
                };
            })
            .catch((error: Error) => {
                if (error.cause === 'aoi-too-small') {
                    error.message = `For new images from space the minimum area of the Order Window must be larger than 50km². Currently, your Order Window is ${areaInKm}km².`;
                } else if (error.cause === 'aoi-too-big') {
                    error.message = `For new images from space the maximum area of the Order Window must be smaller than 1000km². Currently, your Order Window is ${areaInKm}km².`;
                }
                throw error;
            });
    }

    public static createOrder(
        aoi: LatLngBounds,
        scenes: string[],
        supplierCode: SupplierCode,
        produceCode: ProductCode,
        stripeToken: string,
        userCompany: string,
        userIndustry: Industry,
        userCountry: CountryInfo,
        voucher?: string
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Promise<any> {
        const geometry = GeoUtil.latLngBoundsToWKT(aoi);
        const countryCode = JSON.parse(userCountry as unknown as string).value;
        const data = {
            voucher: voucher,
            geometry: geometry,
            archives: scenes.join(','),
            stripeToken: stripeToken,
            company: userCompany.toString(),
            industry: userIndustry.toString(),
            countryCode: countryCode,
        };

        const url = `/v1/archive/order?supplier=${supplierCode}&product=${produceCode}`;
        return this.axios.post(url, data);
    }

    public static createMultipleOrders(
        orders: SatelliteArchiveOrderDTO[],
        company: string,
        industry: string,
        countryCode: string,
        stripeToken: string
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Promise<any> {
        const data = {
            products: orders.map((order) => {
                return {
                    supplierCode: order.supplier,
                    productCode: order.product,
                    geometry: order.geometryWKT,
                    archive: order.orderType === 'ARCHIVE' ? order.id : undefined,
                };
            }),
            billingInfo: {
                company,
                industry,
                countryCode,
            },
            payment: {
                stripeToken,
            },
        };
        const url = `/v1/orders`;
        return this.axios.post(url, data).then((res) => res.data);
    }
}
